

declare var XLSX:any;

import  SpreadSheet from "../../components/SpreadSheet.vue";
import  SerialConnection from "../../components/SerialConnection.vue";

import { Serial ,SerialUsbAutoConnectionDelegate} from "../../modules/hardware/serial";
import CameraConnection from "../../components/CameraConnection.vue";
import { Media } from "../../modules/hardware/media";
import {Geolocation , GeolocationCallback } from "../../modules/hardware/geolocation";
const detectCircleWorker = new Worker('../../modules/app/opencv/workers/detectcircle.js', { type: 'module' });

const piexif =  require('piexifjs');

import { faFile } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios';

// const Plugin  = require( "../../modules/app/plugin").Plugin;
// const cv  = require( "../../assets/opencv/opencv.js").cv;

const moment = require('moment');

const SheetUtil  = require( "../../modules/app/spreadsheet.js").SheetUtil;
// declare var cv: any;
// import XSpreadSheet  from "x-data-spreadsheet";

const cv = require('opencv.js');

const hov = 59.9484; 

const calibrationLongLengthMeterThreshold = 0.5 ;


var detectCircleSem = false;
// var circles = new cv.Mat();
var selectedCircleNo = 0 ;

const measurementTimerMs = 1000;

let detectCircleSetting :any  = {
    currentNum : 2 ,
    1: {
         dp: 1,
         minDist: 15,
         param1 : 50,
         param2: 20,
         minRadius : 25,
         maxRadius : 55
    },
    2: {
         dp: 1,
         minDist: 25,
         param1 : 50,
         param2: 20,
         minRadius : 70,
         maxRadius : 110
    },
    3: {
         dp: 1,
         minDist: 50,
         param1 : 50,
         param2: 20,
         minRadius : 95,
         maxRadius : 150
    } 
    
};

function downloadBase64Image(linkSource:string, fileName:string) {
     // const linkSource = `data:${contentType};base64,${base64Data}`;
     const downloadLink:any = document.createElement("a");
     downloadLink.href = linkSource;
     downloadLink.download = fileName;
     downloadLink.click();
}
/*
function dataURLtoFile(dataurl:string , filename :string ) {
 
        var arr:any = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
}
    */
function degToDmsRational(degFloat :any ) {
    var minFloat = degFloat % 1 * 60
    var secFloat = minFloat % 1 * 60
    var deg = Math.floor(degFloat)
    var min = Math.floor(minFloat)
    var sec = Math.round(secFloat * 100)

    deg = Math.abs(deg) * 1
    min = Math.abs(min) * 1
    sec = Math.abs(sec) * 1
  
    return [[deg, 1], [min, 1], [sec, 100]]
}
  
function convertSensorDataToArr (tex:any){
        const arr = [];
        const texs =tex.split('\r\n');
        for ( var i=0 ; i < texs.length; i++ ){
                    const text = texs[i];
            
                    if(text.indexOf('E') !== -1) { continue; }    
               
                    const result = text.match(/D=(.*)m,/);
                 
                    if (result != null && result.length != 0 ) {  
        
                        arr.push( Number(result[1]) );
                    }
                  }
             return arr;
}

function refreshDetectCircleBtn () {
    const btn1:any = window.document.getElementById( "detectCircleSettingBtn1"); 
    btn1.className = "btn btn-dark";
   // btn1.classList.remove("btn-success"); btn1.classList.add("btn-dark");
              
    const btn2:any = window.document.getElementById( "detectCircleSettingBtn2"); 
    btn2.className = "btn btn-dark";
   // btn2.classList.remove("btn-success");  btn2.classList.add("btn-dark");
        
    const btn3:any = window.document.getElementById( "detectCircleSettingBtn3"); 
    btn3.className = "btn btn-dark";
    // btn3.classList.remove("btn-success"); btn3.classList.add("btn-dark");
}
       // cv.HoughCircles(gray,circles,cv.HOUGH_GRADIENT,1,20,  50, 30,40,80);
const white = new cv.Scalar(255, 225, 255,100);
const green = new cv.Scalar(100, 225, 205,100);

interface ICaribration {
  serial1Value:      number;
  serial2Value:      number;
  add:      Function;
  ratio : Function;
}
 

export default {
  name: "BoreholeApp" ,
  data(): any {
          return {
        isDebugMode : false ,
        debugModeCount : 0 ,
         calibrationA : -5.63662791,
         calibrationB:  1.10755814 ,
              
          calibrationLongLengthMeter : 0.562 , 
          calibrationShortLengthMeter  : 0.135 ,

              testSettingVisble  : false,
          testDetectCircleSetting : {
         dp: 1,
         minDist: 20,
         param1 : 50,
         param2: 30,
         minRadius : 40,
         maxRadius : 80
    },
          geolocation : undefined , 
          gps : { lat : 0 , lon : 0},
          calibration : {
            isLowerThanBothLengthChecked : true ,
            use : "add",
            serial1Value : 0,
            serial2Value : 0,
             add :function( k:any,v:number) :number {
                const key:  keyof ICaribration = k;
                console.log(key);
                const va = this[key] as number;

                    return  v + va  ;
            } , 
            ratio : function(k:any ,v:number) :number {
                       const key:  keyof ICaribration = k;
                       const va = this[key] as number;
                       return  v / va  ;
            }
          } as ICaribration,
       
        objectDetectingSetting : { area : { y : { top : 0.2, bottom: 0.25  } ,  x : { left : 0.2, right: 0.2  }  }},
         faFile :faFile,
        objectDetectingPoint : { start : { x:0 ,y:0 } , end : { x:0,y:0} } ,
        serialIntervalTaskFunc : undefined ,
        filename : null , 
        
       SpreadSheetKey : 0 , 
       avgLength : 0,
       fullLength : 0 ,
       diameter : 0 ,  
       DiameterCorrectionValue : 
       { detectStartPoint : 69  , coefficient :  1   },
         LengthCorrectionValue : {
            side: { sub: 0 } ,
            center : { thr : 830 , add : { above: 0 ,  below: 0 } }
        },
    
       selectCircleMode : false , 
       detectCircleIntervalID : undefined , 
       currentCircle : { center: 0 , radius: 0 } , 
        circles : undefined , 
       serial1Value: 0 ,
       serial2Value: 0 ,
       
       serial1Error : "",
       serial2Error : "",
       
         viewState:  {
            isCanvasShow :false,
            isExcelShow :true,
            isSettingShow :false,
            isExcelFileMenuShow : false,
            isExcelSelectCellMenuShow:false,
            selectCellMode : false ,
            fullLengthCellSelected : false ,
            diameterCellSelected: false ,
            gpsCellSelected : false
            }, 
         cameraInput :undefined,
         cameraOutput: undefined ,
         serial1 : undefined ,
         serial2 : undefined ,
         xlsxUpdated : false , 
      xSpreadSheetOption : { 
             showToolbar: false,
             showGrid: true,
                showContextmenu: false,
            },  
        xSpreadSheetData :[{}] ,
        
        selectedFullLengthCell: { 'row': -1 , 'col': -1 },
        selectedDiameterCell: { 'row': -1 , 'col': -1 },
        selectedGPSCell: { 'row': -1 , 'col': -1 },

        media1 : undefined ,
         videoWidth:0,
         videoHeight:0 ,
         colWidth : 0 ,
         rowHeight : 0,
        
         canvas2dCtx : undefined,
         presetVideo: undefined 
      }
  },
  components:  { SerialConnection,CameraConnection,SpreadSheet } ,
  beforeUnmount(this: {  outsideClickEvent : any }) {
      
        window.removeEventListener('message', this.outsideClickEvent);
  },
  mounted :async function( this: any ){
      window.document.addEventListener('click',this.outsideClickEvent );
      
        this.geolocation = new Geolocation (
            new class implements GeolocationCallback {
               public gps :any;
                constructor (gps:any){ this. gps = gps ; } 
                geolocationReceived = ( pos :any ):void => {
                     this.gps.lat = pos.coords.latitude;
                     this.gps.lon = pos.coords.longitude;
                }
                geolocationFailed = ( error :any ):void =>  {} 
            }(this.gps)  
        );
      
      this. xlsxUpdated = true;
      this.$emit('show-header');
      //9600/19200/38400/115200, default 38400
      this.serial1 = new Serial( { baudRate:38400 , name : "serial1" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
        
      this.serial2 = new Serial( { baudRate:38400 , name : "serial2" , filter : { "usbProductId": 24577, "usbVendorId": 1027 } } );
    
          
      var con = new SerialUsbAutoConnectionDelegate(); 
       
      
      con.push(this.serial1);
      con.push(this.serial2);
      con.enable();
     await con.connectIfConnectionExists();
      
      this.canvas2dCtx = this.$refs.canvas.getContext("2d");
       
        this.media1= new Media({
          video : { facingMode: { exact: "environment" } },
         // video : {   facingMode:  "user"  }, 
          audio :false,
      }); 
      
      this.cameraInput = this.$refs.canvas;
      this.cameraOutput = this.$refs.canvasOutput;
      this.presetVideo = (video:any)=>{
              
                video.style.display = "none";
                this.$refs.canvas.style.display = "block";
               try{
               if (this.detectCircleIntervalID ){
                   clearInterval( this.detectCircleIntervalID);
               }
               }catch (e){ console.log(e); }
               
        this.detectCircleIntervalID = setInterval( ()=>{
                 if( !this.selectCircleMode){
                     console.log("detect mode on");
                         this.geolocation.getCurrentPosition();
                     console.log(this.gps);
                        this .colWidth   = this. videoWidth / 4;
                        this. rowHeight   = this. videoHeight / 4;
                        this.detectCircle();   
                    }   
            }  ,1000);
             
             video.addEventListener('play', () => {
                        const func = ()=>{ 
                           
                            this.videoWidth = video.videoWidth;
                            this.videoHeight = video.videoHeight;
                            this.canvas2dCtx.drawImage(  
                                video, 0,0 , 
                                video.videoWidth  ,
                                video.videoHeight 
                            ); 
                               window.requestAnimationFrame(func);
                        }
                         window.requestAnimationFrame(func);
                   },true ); 
            };     
  }
  ,updated(this:any){
    if ( this.xlsxUpdated ) {
        this.setXlsxFunc();
        this.xlsxUpdated = false ;
    }
  }
  , created(){} 
  ,methods:{
      gpsLink (this:any){
            if (window.confirm("位置情報の確認 外部リンクを開きますか？ ")){
                 window.open("https://www.google.co.jp/maps/@"+this.gps.lat+","+this.gps.lon+",15z", '_blank');
          }
      },
      raitoValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
         if(0.5 < v){
             return v/ calibrationLongLengthMeter ;
         } 
         return v /  calibrationShortLengthMeter;
      }, 
      addValue(v:number, calibrationLongLengthMeter : number , calibrationShortLengthMeter :number ){
        if(0.5 < v){
             return calibrationLongLengthMeter -v;
         } 
         return  calibrationShortLengthMeter -v;  
      },
      async calibrationStart( this:any ){
          
          await this.measurement();
          
      },
      calibrationAdd (this:any){
            this.calibration.use = "add";

            if( 0.5< this.serial1Value){
                  this.calibration.serial1Value = Number(this.calibrationLongLengthMeter) - Number( this.serial1Value )  ;      
            } else {
                  this.calibration.serial1Value =  Number(this.calibrationShortLengthMeter) - Number ( this.serial1Value )  ;           
            }
            if( 0.5< this.serial2Value){
                  this.calibration.serial2Value =  Number(this.calibrationLongLengthMeter )-Number(this.serial2Value ) ;  
            
            } else {
                  this.calibration.serial2Value = Number(this.calibrationShortLengthMeter) -Number( this.serial2Value)  ;           
            }
            
        alert( "設定しました。" );
      },
      calibrationRatio (this:any){
            this.calibration.use = "ratio";
        
            if( calibrationLongLengthMeterThreshold < this.serial1Value){
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial1Value = this.serial1Value /  this.calibrationShortLengthMeter ;           
            }
            if( calibrationLongLengthMeterThreshold < this.serial2Value){
                  this.calibration.serial2Value = this.serial2Value / this.calibrationLongLengthMeter   ;      
            } else {
                  this.calibration.serial2Value = this.serial2Value /  this.calibrationShortLengthMeter ;           
            }
            
             alert( "設定しました。" );
            
      },
      ceilDemicalPoint (v:any , unit :number ){
      
       var s =String(v);
       var ss = s.split('.');
       if(1 < ss.length){
           
           var u:any = "";
           for ( var i=0; i< ss[1].length ; i ++ ){
               if( i < unit ){
                 u += ss[1][i];
               }
           } 
       }
       if(u===undefined) { u = "0"; }
       return ss[0] +"." + u;
      /* 
      桁溢れするので↑の実装..
       const u = (10**unit);
        var r = v * u;
       console.log(v);
                console.log(r);
      
         r = Math.ceil(v) 
        return r / u ;  
        */
      },
      exchangeToColName( num : number ){
                      return ["","A","B","C","D","E","F","G","H","I","J","K","L","M","N","O", "P","Q","R","S","T","U","V","W","X","Y","Z"][num];
        } ,
       async showArea(this : { viewState:any , cameraStart:any} ,area:string){
        
        this.viewState.isCanvasShow = false;
        this.viewState.isExcelShow = false;
        this.viewState.isSettingShow = false;
        switch( area ){
            case 'Canvas' :
                this.viewState.isCanvasShow = true;
                await this.cameraStart();
            break;
            case 'Excel':
                this.viewState.isExcelShow = true;              
            break;
            case 'Setting':
                 this.viewState.isSettingShow = true;
            break;
        }  
        
    },
    async cameraStart (this:{$refs:any}){
        
          try{  await this.$refs.cameraConnection.open();  } catch (e){ console.log(e);}
    },
    showExcelMenu (this:{ viewState:any},state:string){
        
        switch(state){
            case 'File' :
             this.viewState.isExcelSelectCellMenuShow = false;
             this.viewState.isExcelFileMenuShow = !this.viewState.isExcelFileMenuShow ;
            break;
            case 'CellSelection': 
            this.viewState.isExcelFileMenuShow = false ;
            this.viewState.isExcelSelectCellMenuShow = !this.viewState.isExcelSelectCellMenuShow;
            break;
      }
    },
    onDebugMode(this:any){
        this.debugModeCount ++  ;
        if ( 29 < this.debugModeCount  ){
            this.isDebugMode=true;
        }
    },
    selectCircle(this:any, modeOn = true ){
        this.selectCircleMode = modeOn;
        if ( !this.circles  ){ return ; }
        if ( !this.circles.cols  ){ return ; }
        
        let src = cv.imread(   this.cameraInput  );// this.cameraInput);  
        let dst = new cv.Mat.zeros(src.rows, src.cols, cv.CV_8UC4);
        // dst.setTo(new cv.Scalar(0,10,0,1));

        for (let i = 0; i < this.circles.cols; ++i) {
             let x = this.circles.data32F[i * 3] + this.colWidth;
            let y = this.circles.data32F[i * 3 + 1] + this.rowHeight;
            let radius = this.circles.data32F[i * 3 + 2];
            let center = new cv.Point(x, y);
            cv.circle(dst, center, radius, white);
        }
        selectedCircleNo = ( selectedCircleNo >= this.circles.cols-1  ) ? 0 : selectedCircleNo + 1 ;
       
        this.currentCircle.radius = this.circles.data32F[selectedCircleNo * 3 + 2];
        this.currentCircle.center = new cv.Point(
            this.circles.data32F[selectedCircleNo * 3] + this.colWidth , // x
            this.circles.data32F[selectedCircleNo * 3 + 1]  + this.rowHeight // y
        );
        
        cv.circle(dst,  this.currentCircle.center,  this.currentCircle.radius, green,2);   
        cv.imshow( this.cameraOutput, dst);
        
        src.delete(); dst.delete(); 
        this.calculateDiameter();
        
    },
    selectDetectCircle(this:any,num:number ){
      
    
        this.selectCircleMode = true;
        detectCircleSetting.currentNum = num ;
        refreshDetectCircleBtn();
        const btn:any = window.document.getElementById( "detectCircleSettingBtn" + String( num ) );
        btn.classList.remove("btn-dark");
        btn.classList.add("btn-success");
        this.detectCircle();
        this.calculateDiameter();
    },
    detectCircle(this:any){
               
       detectCircleWorker .onmessage =(d :any ) => {
       
       if ( !d.data.error ){
              try{
                    if( !this.selectCircleMode){
                                
                        this.circles = d.data.data.circles; 
                        selectedCircleNo  = -1;   
                        this.selectCircle(false);
                    }
                }catch(e){ console.log(e); }
            }
            detectCircleSem = false;
              
        }
       if( !detectCircleSem ) {
            detectCircleSem = true;
            const para = JSON.parse(JSON.stringify(this.testDetectCircleSetting)) ;
            
            detectCircleWorker.postMessage({
                type : "detect",
                imgData : this.canvas2dCtx.getImageData(0, 0,  this. cameraInput.width ,  this.cameraInput.height),
                crop : { rect :   new cv.Rect (  this.colWidth ,  this.rowHeight   , this.colWidth*2 , this.rowHeight * 3 ) }, 
                setting : { 
                    dp: Number(para.dp),
                    minDist: Number(para.minDist),
                    param1 : Number(para.param1),
                    param2: Number(para.param2),
                    minRadius : Number(para.minRadius),
                    maxRadius : Number(para.maxRadius)
                }
                      //detectCircleSetting[ detectCircleSetting.currentNum ]
            });
        }

    },
      calculateDiameter(  this: any ){

      if(this.avgLength){
   // let x = this.currentCircle.radius*2;
  //  let A=22.07 ; let B=0.23 ; let C=0.00842;
 // this.currentCircle.radius = ((  this.currentCircle.radius* 1.16 ) -2.52 ) / 2 ;
  //  this.currentCircle.radius = ( A+B* x +(C*C) )* 2;
    // this.currentCircle.radius  =  this.currentCircle.radius;
     const LeaserStartingPoint =  this.DiameterCorrectionValue.detectStartPoint ;
    const destAngle = ( ( (this.currentCircle.radius  ) /  560 /*554.25*/ /*this.cameraOutput.width*/ ) * (hov) ) ;
    if(destAngle <0){ return ; }
    const rad = ( ( destAngle) * Math.PI / 180 ) ;
     
     
     // const d =  ( ( this.avgLength + LeaserStartingPoint ) * Math.tan ( rad  ))*2 ;
     const d =  ( ( (  ( this.avgLength - this. calibrationA ) /this. calibrationB  ) + LeaserStartingPoint ) * Math.tan ( rad  ) )*2 ;
     
     if( d < 0   ){ return ;}

       this.diameter =  d*1.05; // (d* this.DiameterCorrectionValue.coefficient) * / diameterCoefficient;
     }
    // alert(rad);
},
xlsxFunc  (this:any , cell: any, row: number, col: number){
          
          if ( this.viewState.selectCellMode ){
              if( !this.viewState.fullLengthCellSelected){
                  
                  if (window.confirm("削孔長のセルを設定しますか？")){
                      this.selectedFullLengthCell.row = row;
                      this.selectedFullLengthCell.col = col;
                     this.viewState.fullLengthCellSelected = true;
                  }
              } else
               if(! this.viewState.diameterCellSelected){
                   
                  if (window.confirm("削孔径のセルを設定しますか？")){
                      this.selectedDiameterCell.row = row;
                      this.selectedDiameterCell.col = col;
                      this.viewState.diameterCellSelected = true;
                   }
              }else 
               if(! this.viewState.gpsCellSelected){
                  if (window.confirm("位置情報のセルを設定しますか？")){
                      this.selectedGPSCell.row = row;
                      this.selectedGPSCell.col = col;
                      this.viewState.gpsCellSelected = true;
                      this.viewState.selectCellMode = false;
                   }     
                  
              }
            }
},
  setXlsxFunc (this:any){
      
            this.$refs.spreadsheet.getSpreadSheet().on('cell-selected', this.xlsxFunc );   
  },
  exportXlsx(this :{ $refs :any }){
           const filename = window.prompt( "ファイル名を入力して下さい。");
           if(filename == "" || filename == null ){ return ; }
           const new_wb = SheetUtil.xtos( this.$refs.spreadsheet.getSpreadSheet().getData());

          XLSX.writeFile(new_wb, filename+".xlsx");
       },
    async saveXlsx(this :any){
           if( this.filename == "" || this.filename == null ){ 
                 this.$emit('show-flash',{"message":"ファイル名がありません。","type": "warning"});          
                return ;
            }
           
           const new_wb = SheetUtil.xtos( this.$refs.spreadsheet.getSpreadSheet().getData());
              
            try{
          // alert( JSON.stringify (this.$refs.spreadsheet.getSpreadSheet().getData()) );
               
            var wbout = XLSX.write(new_wb, {
            bookType:'xlsx',
            bookSST:true,
            type:'base64'
        });
        
         const r = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data:wbout,filename: this.filename + ".xlsx"  });
   
          if(r.data.error==0){
              
                  this.$emit('show-flash',{"message":"ファイルを保存しました。","type": "success"});        
          } else {
               this.$emit('show-flash',{"message":"ファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});  
              
                 XLSX.writeFile(new_wb, this.filename+".xlsx");
                      
          }
          }catch(e){
               this.$emit('show-flash',{"message":"ファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
              
                  XLSX.writeFile(new_wb, this.filename+".xlsx");      
          }
          
       },
        
  importXlsx(this :{ $refs :any,xSpreadSheetData :any , SpreadSheetKey:number ,  xlsxUpdated :boolean},e:any){
           
      if (e.target instanceof HTMLInputElement) { 
        const file =  e.target.files[0];
        const reader : any = new FileReader();
        reader.onload = () => {
            
          const wb = XLSX.read(reader.result, { type: "binary" })
          this.xSpreadSheetData = SheetUtil.stox(wb);
          this.SpreadSheetKey ++ ;
          this.xlsxUpdated = true ;
          // this.$refs.spreadsheet.updateSpreadSheet(this.xSpreadSheetData);
        }
         reader.readAsBinaryString(file);
          
    }  
  
       },   
        outsideClickEvent (this:{ viewState:any },e:any)  { 
            // alert(e.target.className);
              if(  e.target.className.indexOf( 'show-excel' ) ===  -1 ){  this. viewState. isExcelFileMenuShow = false;  }
          },
       selectCellMode( this:any ){
                 alert("データを記入するセルを選択してください。");
                // this.$emit('show-flash',{"message":"データを記入するセルを選択してください。","type": "success"});   
                this.showArea('Excel');
                this. viewState. isExcelFileMenuShow = false; 
                this.viewState.selectCellMode = true;   
                this.viewState.fullLengthCellSelected = false;
                this.viewState.diameterCellSelected = false;
                this.viewState.gpsCellSelected = false;

          
       } ,
        async startMeasurement( this:any){
           if (  !this.filename || this.filename == ""){
             
           this.filename = window.prompt( "ファイル名を入力して下さい。");
           if ( !this.filename || this.filename == "" ){
               
             this.$emit('show-flash',{"message":"ファイル名をご入力してください。","type": "warning"});      
         
               return ; 
           }
         }
         
          if( 
           (  this.selectedDiameterCell.row === -1 && this.selectedDiameterCell.col === -1 ) ||
            this.selectedFullLengthCell.row === -1, this.selectedFullLengthCell.col === -1 || 
            this.selectedGPSCell.row === -1, this.selectedGPSCell.col === -1
            
            ){
                this.selectCellMode();
                return ;
          } 
           this.showArea('Canvas');
              await this.measurement();

        },
       
        async measurement ( this : any ) {
     
            try{ if(  !( await this.serial1?.getInfoAsync()) ){ await this.serial1?.connect(); } } catch(er){ console.log(er); }
            try{ if( !( await this.serial2?.getInfoAsync()) ){ await this.serial2?.connect();} } catch(er){ console.log(er); }
         
            try{
                                
                this.serialIntervalTaskFunc= setInterval( async ()=>  {
                       
                    this.serial1?.setReaderLock();
                       
                    await this.serial1?.sendString("iSM\n");
                    var text1:any =  await this.serial1?.readString();  
                
                    const arr1=convertSensorDataToArr( text1);
                    
                    for(let i = 0 ;  i < arr1.length ; i++){     
                         this.serial1Value = this.calibration[ this.calibration.use ] ( "serial1Value",  arr1[i] ); 
                    }
               
                    await this.serial1?.releaseReaderLock();
          
                    this.serial2?.setReaderLock();
                        
                    await this.serial2?.sendString("iSM\n");
                    var text2:any =  await this.serial2?.readString();  
                        
                    const arr2 =convertSensorDataToArr( text2);
                    
                    for(let i = 0 ;  i < arr2.length ; i++){     
                         this.serial2Value = this.calibration[ this.calibration.use ] ( "serial2Value",  arr2[i] ); 
                    }
               
                    await this.serial2?.releaseReaderLock();
           
               
                    const values = [  this.serial1Value * 1000 ,this.serial2Value*1000].sort(
                        function (a, b) { return a - b }
                    );
           
                    const v1 =  values[0] - this.LengthCorrectionValue.side.sub;
                    var v2 =  values[1] - this.LengthCorrectionValue.side.sub;
                 //   var v3 = values[2];
                    
                   this.avgLength = v1 ; 
             
                    if(
                        v2 <= this.LengthCorrectionValue.center.thr 
                    ){
                
                        v2 = v2 +this.LengthCorrectionValue.center.add.above;
                    }else{
                
                        v2 = v2+ this.LengthCorrectionValue.center.add.below;
                    }
                    this.fullLength = v2 - this.avgLength;
                }  , measurementTimerMs );
               
              }catch(e){
                
                this.serial1?.releaseReaderLock();
                this.serial2?.releaseReaderLock();
              }
        },
        async stop ( this : any ) {
          if (! this.filename ){
                       this.$emit('show-flash',{"message":"計測を開始してください。","type": "warrning"});          
              return;
          }             
        
           const filename =  this.filename + "-" + moment().format('YYYY-MM-DDTHH:mm:ss') + ".jpg";
         
        try{
            var base64img = this.$refs.canvas.toDataURL('image/jpeg', 1.0);
         
            if ( this.gps.lat && this.gps.lon ){
                const exifObj = piexif.load(base64img);
        
                exifObj.GPS[piexif.GPSIFD.GPSLatitude] =  degToDmsRational( this.gps.lat );
                exifObj.GPS[piexif.GPSIFD.GPSLatitudeRef] =  "N";
                exifObj.GPS[piexif.GPSIFD.GPSLongitude] =  degToDmsRational( this.gps.lon );
                exifObj.GPS[piexif.GPSIFD.GPSLongitudeRef] =  "W";

                const exifbytes = piexif.dump(exifObj);
                base64img = piexif.insert(exifbytes, base64img);
            } 
            const base64string = base64img.split('base64,')[1];       
        
           
            const  r  = await axios.post (process.env.VUE_APP_API_URL +"Base64DataUploadHttpTrigger/"+ process.env.VUE_APP_API_CODE ,
          {data: base64string ,filename:  filename }
            );
            
          if(r.data.error==0){
                  this.$emit('show-flash',{"message":"ファイルを保存しました。","type": "success"});        
          } else {
               this.$emit('show-flash',{"message":"ファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
                 downloadBase64Image(base64img,filename);
              
          }
          }catch(e){
               
               this.$emit('show-flash',{"message":"ファイルの保存に失敗しました。ネットワークを確認してください。","type": "danger"});      
                  downloadBase64Image(base64img,filename);
         }
      
            this.showArea('Excel');
            
            const sheet = this.$refs.spreadsheet.getSpreadSheet();
            
            sheet.cellText( this.selectedFullLengthCell.row , this.selectedFullLengthCell.col ,
                this.ceilDemicalPoint ( this.fullLength * 0.001 ,3 ) ).reRender();
            
            sheet.cellText( this.selectedDiameterCell.row , this.selectedDiameterCell.col ,
                this.ceilDemicalPoint ( this.diameter * 0.1 ,1 )
            ).reRender();      
            
            
            sheet.cellText( this.selectedGPSCell.row , this.selectedGPSCell.col ,
                 this.gps.lat +","+ this.gps.lon
            ).reRender();      
            
            
            
            this.selectedFullLengthCell.row = this.selectedFullLengthCell.row + 1 ;
            this.selectedDiameterCell.row  = this.selectedDiameterCell.row  + 1 ;
            this.selectedGPSCell.row  = this.selectedGPSCell.row  + 1 ;
            
        }          
    }
    
}

